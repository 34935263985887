#graphiql {
  height: 100vh;
  font-family: "colfax-web"; }

.intro {
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center; }

.intro__button {
  font-family: "colfax-web";
  background: linear-gradient(221.82deg, #FF7059 0%, #FF664E 63.02%, #F2543A 100%);
  line-height: 50px;
  vertical-align: top;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 0 25px;
  border: none;
  font-size: 25px; }

.intro__title {
  font-size: 40px;
  font-weight: 500px;
  margin-bottom: 20px; }

.intro__description {
  margin-bottom: 20px; }

.intro__input {
  font-size: 25px;
  padding: 10px;
  margin-right: 20px;
  border: 1px solid #ddd;
  width: 550px;
  text-align: center; }

.graphiql-container {
  .toolbar-button {
    max-width: none !important; } }

.graphiql-container .execute-options > li,
.graphiql-container .toolbar-menu-items > li,
.graphiql-container .toolbar-select-options > li {
  max-width: none !important; }
